import * as OfflinePluginRuntime from 'offline-plugin/runtime';



import './tienda.html';
import './tienda.scss';
import './scripts/script';





OfflinePluginRuntime.install();


